$fontFamily: 'Inter';
$fontColor:#374151;
$paratextColor:#27272A;
$borderColor:#E2E8F0; 
$btnbackgroundColor:#171618;
$pageBg:#FAFAFA;
$blackColor:#000000;
$whitecolor:#ffffff;
$pricingLabelColor:#a21caf;
$pricingBoxBg:#F8FAFC;
 
.H1 {
    font-family: $fontFamily;
    color: $fontColor;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
}

.H2 {
    font-family: $fontFamily;
    color: $blackColor;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px 
}

.H3 {
    font-family: $fontFamily;
    color: $blackColor;
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
}

.H4 {
    font-family: $fontFamily;
    color: $blackColor;
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
}

 
.p1{
    font-family: $fontFamily;
    font-size: 20px;
    font-weight: 400;
    color: $fontColor;
    line-height: 28px; 
}

.p2 {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 18px;
    color: $fontColor;
    line-height: 28px;
}

.p3 {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 20px;
    color: $fontColor;
    line-height: 24px;
}

.p4 {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 12px;
    color: $fontColor;
    line-height: 16px;
}

.p5 {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 14px;
    color: $fontColor;
    line-height: 16px;
}

.p6 {
    font-family: $fontFamily;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  //  color: $fontColor;
    
}

.p7 {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $fontColor;
    
}

.p8 {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $fontColor;
    
}

.p9 {
    font-family: $fontFamily;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $blackColor;
    
}

.p10 {
    font-family: $fontFamily;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: $blackColor;
    
}

.linkHeader {
   font-family: 'Inter';
   font-size: 14px; 
   color: $fontColor;
   font-weight: 600; 
   cursor: pointer;
}

.homeHeadingprimary {
   font-family: 'Inter';
   font-size: 72px;
   font-weight: bold;
   color: $blackColor;
   text-align: center;
   line-height: normal;
}

.homeHeadingprimaryMobile{
    font-family: 'Inter';
    font-size: 38px;
    font-weight: bold;
    color: $blackColor;
    text-align: center;
    line-height: normal;
}

.homeHeadingsecondaryMobile{

    font-family: 'Inter';
    font-size: 38px;
    font-weight: 700;
    line-height: normal;
    background-image: linear-gradient(to right,var(--tw-gradient-stops));
    --tw-gradient-from: #d946ef var(--tw-gradient-from-position);
    --tw-gradient-to: rgba(217,70,239,0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: rgba(13,148,136,0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from),#0d9488 var(--tw-gradient-via-position),var(--tw-gradient-to);
    --tw-gradient-to: #d946ef var(--tw-gradient-to-position);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: text-animate 5s linear infinite;
    background-size: 200%
}

.homeHeadingsecondary{
    font-family: 'Inter';
    font-size: 72px;
    font-weight: 700;
    line-height: normal;
    background-image: linear-gradient(to right,var(--tw-gradient-stops));
    --tw-gradient-from: #d946ef var(--tw-gradient-from-position);
    --tw-gradient-to: rgba(217,70,239,0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: rgba(13,148,136,0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from),#0d9488 var(--tw-gradient-via-position),var(--tw-gradient-to);
    --tw-gradient-to: #d946ef var(--tw-gradient-to-position);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: text-animate 5s linear infinite;
    background-size: 200%
 }

// swich style

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 1px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: $blackColor;
}

input:focus+.slider {
    box-shadow: 0 0 1px $blackColor;
}

input:checked+.slider:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
}
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
    
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
.faqCollanpse{
    transition-property: height;
    transition-duration: 200ms;
    overflow: hidden;
     
    
}

  
 
*,:after,:before {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}

::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(59,130,246,.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia:
}


@keyframes text-animate {
    0% {
        background-position: 0
    }

    to {
        background-position: 200%
    }
}

.btnStyle {
    font-family: 'Inter';
    font-weight:400;
    color: $whitecolor;
    font-size: 14px;
    
}

.btnStylePricingTransparent {
    font-family: $fontFamily;
    font-weight:400;
    color: $whitecolor;
    font-size: 16px;
    
}

.btnStylePricing {
    font-family: $fontFamily;
    font-weight:400;
    color: $whitecolor;
    font-size: 10px;
    
}

 

:export {
    borderColor: $borderColor;
    btnbackgroundColor: $btnbackgroundColor;
    pageBg: $pageBg;
    whitecolor: $whitecolor;
    blackColor: $blackColor;
    pricingLabelColor: $pricingLabelColor;
    pricingBoxBg: $pricingBoxBg
}