 
$fontFamily: 'Inter';
$fontColor:#000000;
$paratextColor:#27272A;
$borderColor:#CFDFE2;
$btnbackgroundColor:#F3F9FA;
$pageBg:#FAFAFA; 
$textColor:#71717A; 
$darkTheme:#000000;
$lightTheme:#FAFAFA;
$blackColor:#000000;
$whitecolor:#ffffff;

.H1{
    font-family: $fontFamily;
    color:$fontColor;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    
 }

.H2{
    font-family: $fontFamily;
    font-size: 24px;
}

.H3{
    font-family: $fontFamily;
    font-size: 18px;

}

.H4{
    font-family: $fontFamily;
    font-size: 16px;
}
.H5{
    font-family: $fontFamily;
    font-size: 13px;
}
.H6{
    font-family: $fontFamily;
    font-size: 10px;
}
.H7{
    font-family: $fontFamily;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
}
 
.H7dark{
    font-family: $fontFamily;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    color: $whitecolor;
} 


.p1{
    font-family: $fontFamily;
    font-size: 16px;
    color: $paratextColor;
}

.p2{
    font-family: $fontFamily;
    font-size: 17px;
    color: $paratextColor;
    font-weight: 400;
}

.p3{
    font-family: $fontFamily;
    font-size: 14px;
    color: $paratextColor;
    font-weight: 400;
}

.p4{
    font-family: $fontFamily;
    font-size: 13px;
    color: $paratextColor;
    font-weight: 400;
    line-height: normal;
}

.p4dark{
    font-family: $fontFamily;
    font-size: 13px;
    color: $whitecolor;
    font-weight: 400;
    line-height: normal;
}

.p5{
    font-family: $fontFamily;
    font-size: 16px;
    color: $paratextColor;
    font-weight: 500;
}

.p6{
    font-family: $fontFamily;
    font-size: 10px;
    color: $textColor;
    font-weight: 400;
}

.p7{
    font-family: $fontFamily;
    font-size: 16px;
    color: $textColor;
    font-weight: 400;
}

:export {
   
    borderColor:$borderColor;
    btnbackgroundColor:$btnbackgroundColor;
    pageBg:$pageBg;
    darkTheme:$darkTheme;
    lightTheme:$lightTheme
}
 